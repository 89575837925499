<template>
  <div>
    <AtualizarSistema/>
  </div>
</template>

<script>
import AtualizarSistema from '@/components/Sistema/AtualizarSistema'
export default {
  components: {AtualizarSistema},
  data(){
    return{}
  }
}
</script>

<style>

</style>